import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { ArrowRightIcon, ChevronDoubleRightIcon } from "@heroicons/react/solid";


const Locations = () => {
  const data = useStaticQuery(graphql`
    {
      prismicContactUs {
        data {
          locations {
            address
            navigation_link {
              url
            }
            place {
              text
            }
            image {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="contact-us-grid md:hidden">
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1">
          <ul className="flex flex-col px-4 py-2 space-y-3">
            <li className="text-4xl font-medium sm:text-4xl text-blue-500">
              Locations
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[16px] col-span-1">
          <ul className = "flex flex-col space-y-2">
            <li>
              <GatsbyImage
                image={getImage(data.prismicContactUs.data.locations[0].image)}

                className = "w-full object-cover aspect-w-16 aspect-h-9 rounded shadow"
              />
            </li>
            <li className = "text-2xl font-regular text-gray-300 pt-3">
                {data.prismicContactUs.data.locations[0].place.text}
            </li>
            <li className = "text-sm font-light text-gray-400">
                {data.prismicContactUs.data.locations[0].address}
            </li>
            <li className = "">
                <a href={data.prismicContactUs.data.locations[0].navigation_link.url} target = "_blank">
                    <button className = "">
                        <p className = "text-sm hover:underline font-regular text-blue-500">Get Directions</p>
                    </button>
                </a>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[16px] col-span-1">
          <ul className = "flex flex-col space-y-2">
            <li>
              <GatsbyImage
                image={getImage(data.prismicContactUs.data.locations[1].image)}

                className = "w-full object-cover aspect-w-16 aspect-h-9 rounded shadow"
              />
            </li>
            <li className = "text-2xl font-regular text-gray-300 pt-3">
                {data.prismicContactUs.data.locations[1].place.text}
            </li>
            <li className = "text-sm font-light text-gray-400">
                {data.prismicContactUs.data.locations[1].address}
            </li>
            <li className = "">
                <a href={data.prismicContactUs.data.locations[1].navigation_link.url} target = "_blank">
                    <button className = "">
                        <p className = "text-sm hover:underline font-regular text-blue-500">Get Directions</p>
                    </button>
                </a>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[16px] col-span-1">
          <ul className = "flex flex-col space-y-2">
            <li>
              <GatsbyImage
                image={getImage(data.prismicContactUs.data.locations[2].image)}

                className = "w-full object-cover aspect-w-16 aspect-h-9 rounded shadow"
              />
            </li>
            <li className = "text-2xl font-regular text-gray-300 pt-3">
                {data.prismicContactUs.data.locations[2].place.text}
            </li>
            <li className = "text-sm font-light text-gray-400">
                {data.prismicContactUs.data.locations[2].address}
            </li>
            <li className = "">
                <a href={data.prismicContactUs.data.locations[2].navigation_link.url} target = "_blank">
                    <button className = "">
                        <p className = "text-sm hover:underline font-regular text-blue-500">Get Directions</p>
                    </button>
                </a>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
      </div>


{/* XL MENU */}


      <div className="contact-us-grid hidden md:grid">
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-2"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-2">
          <ul className="flex flex-col px-4 py-2 space-y-3">
            <li className="text-4xl font-medium sm:text-4xl text-blue-500">
              Locations
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[16px] col-span-1">
          <ul className = "flex flex-col space-y-2">
            <li>
              <GatsbyImage
                image={getImage(data.prismicContactUs.data.locations[0].image)}

                className = "w-full object-cover aspect-w-16 aspect-h-9 rounded shadow"
              />
            </li>
            <li className = "text-2xl font-regular text-gray-300 pt-3">
                {data.prismicContactUs.data.locations[0].place.text}
            </li>
            <li className = "text-sm font-light text-gray-400">
                {data.prismicContactUs.data.locations[0].address}
            </li>
            <li className = "">
                <a href={data.prismicContactUs.data.locations[0].navigation_link.url} target = "_blank">
                    <button className = "">
                        <p className = "text-sm hover:underline font-regular text-blue-500">Get Directions</p>
                    </button>
                </a>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[16px] col-span-1">
          <ul className = "flex flex-col space-y-2">
            <li>
              <GatsbyImage
                image={getImage(data.prismicContactUs.data.locations[1].image)}

                className = "w-full object-cover aspect-w-16 aspect-h-9 rounded shadow"
              />
            </li>
            <li className = "text-2xl font-regular text-gray-300 pt-3">
                {data.prismicContactUs.data.locations[1].place.text}
            </li>
            <li className = "text-sm font-light text-gray-400">
                {data.prismicContactUs.data.locations[1].address}
            </li>
            <li className = "">
                <a href={data.prismicContactUs.data.locations[1].navigation_link.url} target = "_blank">
                    <button className = "">
                        <p className = "text-sm hover:underline font-regular text-blue-500">Get Directions</p>
                    </button>
                </a>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[16px] col-span-1">
          <ul className = "flex flex-col space-y-2">
            <li>
              <GatsbyImage
                image={getImage(data.prismicContactUs.data.locations[2].image)}

                className = "w-full object-cover aspect-w-16 aspect-h-9 rounded shadow"
              />
            </li>
            <li className = "text-2xl font-regular text-gray-300 pt-3">
                {data.prismicContactUs.data.locations[2].place.text}
            </li>
            <li className = "text-sm font-light text-gray-400">
                {data.prismicContactUs.data.locations[2].address}
            </li>
            <li className = "">
                <a href={data.prismicContactUs.data.locations[2].navigation_link.url} target = "_blank">
                    <button className = "">
                        <p className = "text-sm hover:underline font-regular text-blue-500">Get Directions</p>
                    </button>
                </a>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
      </div>
    </>
  );
};

export default Locations;
