import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { ArrowRightIcon } from "@heroicons/react/solid";

const SupportTable = () => {
  const data = useStaticQuery(graphql`
    {
      prismicContactUs {
        data {
          description
          contact_option {
            contact_description
            slug
          }
          page_title {
            text
          }
          contact_form_title {
            text
          }
          contact_form_description
          support_option {
            support_description1
            support_option1 {
              text
            }
            support_slug
          }
          support_description
          support_title {
            text
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="contact-us-grid md:hidden">
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1">
          <ul className="flex flex-col px-4 py-2 space-y-3">
            <li className="text-4xl font-medium sm:text-4xl text-blue-500">
              {data.prismicContactUs.data.support_title.text}
            </li>
            <li className="text-lg text-gray-400 font-light">
              {data.prismicContactUs.data.support_description}
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-5 col-span-1 flex flex-row justify-between items-center">
          <ul className="flex flex-col space-y-2">
            <li className="text-xl text-gray-300 font-medium">
              {
                data.prismicContactUs.data.support_option[0].support_option1
                  .text
              }
            </li>
            <li className="text-sm text-gray-400 font-light">
              {
                data.prismicContactUs.data.support_option[0]
                  .support_description1
              }
            </li>
          </ul>
          <ul>
            <li>
              <Link
                to={data.prismicContactUs.data.support_option[0].support_slug}
              >
                <button className="flex flex-col items-center justify-between">
                  <ArrowRightIcon className="h-6 text-blue-500" />
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-5 col-span-1 flex flex-row justify-between items-center">
          <ul className="flex flex-col space-y-2">
            <li className="text-xl text-gray-300 font-medium">
              {
                data.prismicContactUs.data.support_option[1].support_option1
                  .text
              }
            </li>
            <li className="text-sm text-gray-400 font-light">
              {
                data.prismicContactUs.data.support_option[1]
                  .support_description1
              }
            </li>
          </ul>
          <ul>
            <li>
              <Link
                to={data.prismicContactUs.data.support_option[1].support_slug}
              >
                <button className="flex flex-col items-center justify-between">
                  <ArrowRightIcon className="h-6 text-blue-500" />
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-5 col-span-1 flex flex-row justify-between items-center">
          <ul className="flex flex-col space-y-2">
            <li className="text-xl text-gray-300 font-medium">
              {
                data.prismicContactUs.data.support_option[2].support_option1
                  .text
              }
            </li>
            <li className="text-sm text-gray-400 font-light">
              {
                data.prismicContactUs.data.support_option[2]
                  .support_description1
              }
            </li>
          </ul>
          <ul>
            <li>
              <Link
                to={data.prismicContactUs.data.support_option[2].support_slug}
              >
                <button className="flex flex-col items-center justify-between">
                  <ArrowRightIcon className="h-6 text-blue-500" />
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-5 col-span-1 flex flex-row justify-between items-center">
          <ul className="flex flex-col space-y-2">
            <li className="text-xl text-gray-300 font-medium">
              {
                data.prismicContactUs.data.support_option[3].support_option1
                  .text
              }
            </li>
            <li className="text-sm text-gray-400 font-light">
              {
                data.prismicContactUs.data.support_option[3]
                  .support_description1
              }
            </li>
          </ul>
          <ul>
            <li>
              <Link
                to={data.prismicContactUs.data.support_option[3].support_slug}
              >
                <button className="flex flex-col items-center justify-between">
                  <ArrowRightIcon className="h-6 text-blue-500" />
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
      </div>

{/* XL GRID BELOW */}



      <div className="contact-us-grid hidden md:grid">
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-2"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-2">
          <ul className="flex flex-col px-4 py-2 space-y-3">
            <li className="text-4xl font-medium sm:text-4xl text-blue-500">
              {data.prismicContactUs.data.support_title.text}
            </li>
            <li className="text-lg text-gray-400 font-light">
              {data.prismicContactUs.data.support_description}
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-5 col-span-1 flex flex-row justify-between items-center">
          <ul className="flex flex-col space-y-2">
            <li className="text-xl text-gray-300 font-medium">
              {
                data.prismicContactUs.data.support_option[0].support_option1
                  .text
              }
            </li>
            <li className="text-sm text-gray-400 font-light">
              {
                data.prismicContactUs.data.support_option[0]
                  .support_description1
              }
            </li>
          </ul>
          <ul>
            <li>
              <Link
                to={data.prismicContactUs.data.support_option[0].support_slug}
              >
                <button className="flex flex-col items-center justify-between">
                  <ArrowRightIcon className="h-6 text-blue-500" />
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-5 col-span-1 flex flex-row justify-between items-center">
          <ul className="flex flex-col space-y-2">
            <li className="text-xl text-gray-300 font-medium">
              {
                data.prismicContactUs.data.support_option[1].support_option1
                  .text
              }
            </li>
            <li className="text-sm text-gray-400 font-light">
              {
                data.prismicContactUs.data.support_option[1]
                  .support_description1
              }
            </li>
          </ul>
          <ul>
            <li>
              <Link
                to={data.prismicContactUs.data.support_option[1].support_slug}
              >
                <button className="flex flex-col items-center justify-between">
                  <ArrowRightIcon className="h-6 text-blue-500" />
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
        <div className="bg-gray-900 p-5 col-span-1 flex flex-row justify-between items-center">
          <ul className="flex flex-col space-y-2">
            <li className="text-xl text-gray-300 font-medium">
              {
                data.prismicContactUs.data.support_option[2].support_option1
                  .text
              }
            </li>
            <li className="text-sm text-gray-400 font-light">
              {
                data.prismicContactUs.data.support_option[2]
                  .support_description1
              }
            </li>
          </ul>
          <ul>
            <li>
              <Link
                to={data.prismicContactUs.data.support_option[2].support_slug}
              >
                <button className="flex flex-col items-center justify-between">
                  <ArrowRightIcon className="h-6 text-blue-500" />
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-5 col-span-1 flex flex-row justify-between items-center">
          <ul className="flex flex-col space-y-2">
            <li className="text-xl text-gray-300 font-medium">
              {
                data.prismicContactUs.data.support_option[3].support_option1
                  .text
              }
            </li>
            <li className="text-sm text-gray-400 font-light">
              {
                data.prismicContactUs.data.support_option[3]
                  .support_description1
              }
            </li>
          </ul>
          <ul>
            <li>
              <Link
                to={data.prismicContactUs.data.support_option[3].support_slug}
              >
                <button className="flex flex-col items-center justify-between">
                  <ArrowRightIcon className="h-6 text-blue-500" />
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="bg-gray-900 p-[6px] col-span-1"></div>
      </div>
      
    </>
  );
};

export default SupportTable;
