import React, { useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { useStaticQuery, graphql, Link } from "gatsby";
import Alert from "../alert";

import axios from "axios";
import * as Yup from "yup";
import formErrors from "../Global/formErrors";

const Contact = () => {
  const [isSent, setIsSent] = useState(false);

  const data = useStaticQuery(graphql`
    {
      prismicContactUs {
        data {
          contact_form_description
          contact_form_title {
            text
          }
        }
      }
    }
  `);

  const submitForm = (values) => {
    axios({
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      url: "https://hook.integromat.com/kwzjihfplhpvakud39muesrvac9sdmgx",
      data: JSON.stringify(values),
    })
      .then((r) => {
        console.log("Thanks!", values);
      })
      .catch((r) => {
        console.log("Failed", values);
      });
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("This Field is Required"),
    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("This Field is Required"),
    eMail: Yup.string()
      .email("Please give your E-Mail")
      .required("Please fill your E-Mail Address"),
    mobileNumber: Yup.string().matches(/^[6-9]\d{9}$/, {
      message: "Please enter valid number.",
      excludeEmptyString: false,
    }),
    mainMessage: Yup.string().required("This Field is Required"),
  });

  return (
    <>
      <div className="contact-options-grid">
        <div className="bg-gray-900 col-span-1 py-2"></div>
        <div className="bg-gray-900 col-span-1 py-2"></div>
        <div className="bg-gray-900 col-span-1 py-2"></div>
        <div className="bg-gray-900 col-span-1 py-2"></div>
        <div className="bg-gray-900 col-span-1 px-3 py-5">
          {!isSent ? (
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                eMail: "",
                mobileNumber: "",
                mainMessage: "",
              }}
              onSubmit={(values, actions) => {
                submitForm(values);
                console.log(values);
                actions.setSubmitting(false);
                actions.resetForm();
                setIsSent(true);
              }}
              validationSchema={validationSchema}
            >
              <Form className="form-grid">
                <ul className="flex flex-col pb-8 space-y-3 col-span-2 border-b border-dashed border-gray-700">
                  <li className="text-3xl font-medium sm:text-4xl text-blue-500">
                    {data.prismicContactUs.data.contact_form_title.text}
                  </li>
                  <li className="text-base text-gray-400 font-light">
                    {data.prismicContactUs.data.contact_form_description}
                  </li>
                </ul>
                <div className="form-field-container">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    className="form-input-field"
                  />
                  <ErrorMessage name="firstName" component={formErrors} />
                </div>
                <div className="form-field-container">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    className="form-input-field"
                  />
                  <ErrorMessage name="lastName" component={formErrors} />
                </div>
                <div className="form-field-container">
                  <label htmlFor="eMail" className="form-label">
                    E-Mail Address
                  </label>
                  <Field
                    type="text"
                    id="eMail"
                    name="eMail"
                    placeholder="E-Mail Address"
                    className="form-input-field"
                  />
                  <ErrorMessage name="eMail" component={formErrors} />
                </div>
                <div className="form-field-container">
                  <label htmlFor="mobileNumber" className="form-label">
                    Mobile Number
                  </label>
                  <ul className="flex flex-row">
                    <li className="flex flex-col items-center justify-center px-3 rounded-l bg-gray-800 shadow border-gray-700 border border-r-0 text-gray-400">
                      +91
                    </li>
                    <li className="flex-1">
                      <Field
                        type="text"
                        id="mobileNumber"
                        name="mobileNumber"
                        placeholder="91234567890"
                        className="form-input-field rounded-r rounded-l-none"
                      />
                    </li>
                  </ul>
                  <ErrorMessage name="mobileNumber" component={formErrors} />
                </div>
                <div className="form-field-container col-span-2">
                  <label htmlFor="mainMessage" className="form-label">
                    How may we help you Today?
                  </label>
                  <Field
                    type="textarea"
                    as="textarea"
                    id="mainMessage"
                    name="mainMessage"
                    placeholder="Type your Message here"
                    className="form-input-field h-24"
                  />
                  <ErrorMessage name="mainMessage" component={formErrors} />
                </div>
                <div className="form-field-container col-span-2 flex flex-row justify-end">
                  <button
                    type="submit"
                    className="contact-form-button border-r-0"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </Formik>
          ) : (
            <div className="flex flex-col items-center justify-center py-16 px-8">
              <p className=" text-2xl text-gray-400">
                {" "}
                Thank you For Submitting the Form,
              </p>
              <p className="pt-4 text-xl font-light text-gray-300">
                Please check your Inbox! for further instructions.
              </p>
            </div>
          )}
        </div>
        <div className="bg-gray-900 col-span-1 py-2"></div>
        <div className="bg-gray-900 col-span-1 py-2"></div>
        <div className="bg-gray-900 col-span-1 py-2"></div>
        <div className="bg-gray-900 col-span-1 py-2"></div>
      </div>

      <Alert type="success" message="" />
    </>
  );
};

export default Contact;
