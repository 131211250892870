import React, { useState, useEffect } from "react";

import Layout from "../components/Global/layout";
import { ArrowRightIcon } from "@heroicons/react/solid";
import MainSection from "../components/Contact Us/mainSection";
import SupportTable from "../components/Contact Us/supportTable";
import ContactOptions from "../components/Contact Us/contactOptions";
import ContactForm from "../components/Contact Us/contactForm";
import Locations from "../components/Contact Us/locations";
import { Link } from "gatsby";
import SEO from "../components/seo/SEO";

// markup
const ContactUs = () => {
  useEffect(() => {
    //  saveContact()
  }, []);

  return (
    <>
    <SEO />
      <Layout>
        <main className="">
          <MainSection />
          <ContactOptions />
          <SupportTable />
          <Locations />
          <ContactForm />
        </main>
      </Layout>
    </>
  );
};

export default ContactUs;
