import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { ArrowRightIcon } from "@heroicons/react/solid";

const ContactOptions = () => {
  const data = useStaticQuery(graphql`
    {
      prismicContactUs {
        data {
          description
          contact_option {
            contact_title {
              text
            }
            contact_description
            slug
          }
          page_title {
            text
          }
          contact_form_title {
            text
          }
          contact_form_description
          support_option {
            support_description1
            support_option1 {
              text
            }
            support_slug
          }
          support_description
          support_title {
            text
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="contact-options-grid">
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        <div className="bg-gray-900 py-2 col-span-1"></div>
        {data.prismicContactUs.data.contact_option.map((item) => (
          <>
            <div className="bg-gray-900 py-2 col-span-1"></div>

            <div className="bg-gray-900 p-5 col-span-1 flex flex-row justify-between items-center">
              <ul className="flex flex-col space-y-2">
                <li className="text-2xl font-regular text-blue-500">
                  {item.contact_title.text}
                </li>
                <li className="text-base text-gray-400 font-light pr-5">
                  {item.contact_description}
                </li>
              </ul>
              <ul>
                <li>
                  <Link to={item.support_slug}>
                    <button className="flex flex-col items-center justify-between">
                      <ArrowRightIcon className="h-6 text-blue-500" />
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="bg-gray-900 p-[6px] col-span-1"></div>
          </>
        ))}
      </div>
    </>
  );
};

export default ContactOptions;
