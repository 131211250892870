import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const MainSection = () => {
  const data = useStaticQuery(graphql`
    {
      prismicContactUs {
        data {
          description
          contact_option {
            contact_description
            slug
          }
          page_title {
            text
          }
          contact_form_title {
            text
          }
          contact_form_description
          support_option {
            support_description1
            support_option1 {
              text
            }
            support_slug
          }
        }
      }
    }
  `);
  return (
    <>
      <div className="contact-options-grid mt-16">
        <div className="bg-gray-900 p-2"></div>
        <div className="bg-gray-900 h-full py-8 md:py-12 px-4">
          <div className="flex flex-col space-y-4">
            <h1 className="text-4xl text-blue-500 font-medium">
              {data.prismicContactUs.data.page_title.text}
            </h1>
            <p className="text-lg text-gray-300 font-light sm:w-[480px] leading-relaxed">
              {data.prismicContactUs.data.description}
            </p>
          </div>
        </div>
        <div className="bg-gray-900 p-2"></div>
      </div>
    </>
  );
};

export default MainSection;
